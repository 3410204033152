import {
  EmailLog,
  EmailQuarantineManagerWidgetProps,
} from "EmailQuarantineManagerWidget";
import React, { useCallback, useContext, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  ActionType,
  EmailQuarantineManagerContext,
} from "context/EmailQuarantineManagerWidget";
import { TabPanelProps } from "utils/types";
import { PendingEmails, SentEmails, DiscardedEmails } from "components";
import { formatDateTime } from "utils/helpers";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const getEmailLogInFormat = (log: EmailLog) => {
  let tag = "Normal";
  if (log.isOpsCritical) {
    tag = "Ops Critical";
  }
  if (log.isReminder) {
    tag = "Reminder";
  }
  const from1 = `${log.senderName || ""} <${log.fromAddress || ""}>`;
  const dateTime =
    log.dateTime && !log.dateTime.toLowerCase().includes("invalid")
      ? formatDateTime(new Date(log.dateTime).toString())
      : "-";
  const discardedTime =
    log.discardedTime && !log.discardedTime.toLowerCase().includes("invalid")
      ? formatDateTime(new Date(log.discardedTime).toString())
      : "-";

  return {
    tag,
    name: log.id,
    id: log.id,
    dateTime,
    discardedTime,
    createdTime: formatDateTime(log.createdTime),
    templateName: log.emailName,
    subject: log.subject,
    recipient: (
      <span style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
        To: {log.recipient}
      </span>
    ),
    from: (
      <span>
        {from1} <br />
        Reply To: {log.replyToAddress || ""}
      </span>
    ),
  };
};

export const EmailQuarantineManager: React.FC<EmailQuarantineManagerWidgetProps> =
  ({ apiUrl }) => {
    const { dispatch } = useContext(EmailQuarantineManagerContext);
    const [value, setValue] = React.useState(0);

    const handleChange = (
      event: React.ChangeEvent<unknown>,
      newValue: number
    ) => {
      setValue(newValue);
    };

    const getData = useCallback(() => {
      dispatch({ type: ActionType.SET_API_URL, payload: apiUrl });
    }, [dispatch, apiUrl]);

    useEffect(() => {
      getData();
    }, [getData]);

    return (
      <>
        <Box p={2}>
          <Box mb={2}>
            <Typography variant="h5">
              Email and SMS Quarantine Manager
            </Typography>
          </Box>
          <Box>
            <Box borderBottom={1} borderColor="divider">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="email state tabs"
              >
                <Tab label="Pending" {...a11yProps(0)} />
                <Tab label="Sent" {...a11yProps(1)} />
                <Tab label="Discarded" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <PendingEmails />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <SentEmails />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <DiscardedEmails />
            </CustomTabPanel>
          </Box>
        </Box>
      </>
    );
  };
