import React from "react";
import {
  Box,
  styled,
  TableCell,
  TableCellProps,
  TableSortLabel,
  TableSortLabelProps,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const StyledIcon = styled(InfoOutlined)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.light,
}));

const StyledLabel = styled(Typography)(() => ({
  whiteSpace: "nowrap",
}));

const getFlexJustify = (align: TableCellProps["align"]) => {
  if (align === "center") {
    return "center";
  }
  if (align === "right") {
    return "flex-end";
  }
  return "flex-start";
};

interface TableHeadCellProps extends TableCellProps {
  info?: string;
  header: string;
  width?: number;
  hasSorting?: boolean;
  sortLabelProps?: TableSortLabelProps;
  noCapitalize?: boolean;
}

export function TableHeadCell({
  info,
  header,
  width,
  align,
  hasSorting,
  sortLabelProps,
  noCapitalize,
  ...rest
}: TableHeadCellProps) {
  return (
    <StyledTableCell
      style={{
        width,
      }}
      align={align}
      {...rest}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={getFlexJustify(align)}
      >
        {info && (
          <Tooltip title={info} arrow>
            <StyledIcon color="action" />
          </Tooltip>
        )}

        {hasSorting ? (
          <TableSortLabel {...sortLabelProps}>
            <StyledLabel
              variant="button"
              style={noCapitalize ? { textTransform: "none" } : {}}
            >
              {header}
            </StyledLabel>
          </TableSortLabel>
        ) : (
          <StyledLabel
            variant="button"
            style={noCapitalize ? { textTransform: "none" } : {}}
          >
            {header}
          </StyledLabel>
        )}
      </Box>
    </StyledTableCell>
  );
}
