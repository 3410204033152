import React, {
  useReducer,
  createContext,
  Dispatch,
  FC,
  ReactNode,
} from "react";

interface State {
  apiUrl: string;
}

export enum ActionType {
  SET_API_URL = "SET_API_URL",
}

export interface EmailQuarantineManagerAction {
  type: ActionType;
  payload?: any;
}

export interface IEmailQuarantineManagerContext {
  state: State;
  dispatch: Dispatch<EmailQuarantineManagerAction>;
}

const defaultState: State = {
  apiUrl: "",
};

const defaultDispatch: Dispatch<EmailQuarantineManagerAction> = () => {
  throw new Error("Dispatch function must be overridden");
};

export const EmailQuarantineManagerContext =
  createContext<IEmailQuarantineManagerContext>({
    state: defaultState,
    dispatch: defaultDispatch,
  });

const reducer = (state: State, action: EmailQuarantineManagerAction): State => {
  switch (action.type) {
    case ActionType.SET_API_URL:
      return {
        ...state,
        apiUrl: action.payload ?? state.apiUrl,
      };
    default:
      return state;
  }
};

export const EmailQuarantineManagerContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <EmailQuarantineManagerContext.Provider value={{ state, dispatch }}>
      {children}
    </EmailQuarantineManagerContext.Provider>
  );
};
