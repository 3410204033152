import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { EmailLog } from "EmailQuarantineManagerWidget";
import { formatDateTime } from "utils/helpers";

interface ViewEmailModalProps {
  open: boolean;
  handleClose: () => void;
  emailLog?: EmailLog;
}

export const ViewEmailModal: React.FC<ViewEmailModalProps> = ({
  open,
  handleClose,
  emailLog,
}) => {
  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container justify="space-between">
            <Grid item>View Email Content</Grid>
            <Grid item>
              <Box textAlign="right" pr={4}>
                <Typography variant="body2">
                  Quarantined On: {formatDateTime(emailLog?.createdTime || "")}
                </Typography>
                <Typography variant="body2">
                  Sent On:{" "}
                  {emailLog?.dateTime?.toLowerCase().includes("invalid")
                    ? "NA"
                    : emailLog?.dateTime}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 12,
            top: 12,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography variant="body1">
            <strong>Subject: {emailLog?.subject}</strong>
          </Typography>
          <Typography variant="body1">
            From: {emailLog?.senderName} {emailLog?.fromAddress}
          </Typography>
          <Typography variant="body1">
            Reply To: {emailLog?.replyToAddress}
          </Typography>
          <Typography variant="body1">To: {emailLog?.recipient}</Typography>
          <Typography variant="body1">CC: {emailLog?.cc}</Typography>
          <Typography variant="body1">BCC: {emailLog?.bcc}</Typography>
          <Box mb={2}>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: emailLog?.body.html || "" }}
            />
          </Box>
          <Typography variant="body1">
            Attachments: {emailLog?.attachments?.join(", ")}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
