import apiClient from "./client";

interface ZohoData {
  Entity: string;
  EntityId: string;
}

export function isZoho() {
  // window.location.href may look something like this in zoho iframe
  // https://be-widgets.ngrok.io/account-admin?serviceOrigin=https%3A%2F%2Fcrm.zoho.com&frameorigin=https%3A%2F%2Fcrm.zoho.com
  return window.location.href.includes("crm.zoho");
}

export const ZOHO_SCRIPT_SRC = "/zoho.min.js";

/* eslint-disable @typescript-eslint/no-unsafe-call */
declare const ZOHO: any;

const injectScript = () => {
  const defaultError = new Error("Cannot load ZOHO CRM script!");

  return new Promise<void>((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = ZOHO_SCRIPT_SRC;
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (Object.prototype.hasOwnProperty.call(window, "ZOHO")) {
        resolve();
      } else {
        reject(defaultError);
      }
    });
    script.addEventListener("error", () => {
      reject(defaultError);
    });
  });
};

const init = () => {
  if (!isZoho()) {
    return Promise.resolve({
      Entity: "Contacts",
      EntityId: (window as any).contactZohoId,
    });
  }
  return new Promise<ZohoData>((resolve) => {
    ZOHO.embeddedApp.on("PageLoad", (data: ZohoData) => {
      resolve(data);
    });
    ZOHO.embeddedApp.init();
  });
};

export const signatureForServerAPIWithUserID = async (
  zohoAuthenticationFunctionName = "signatureForServerAPIWithUserID"
): Promise<string | null> => {
  if (!isZoho()) {
    const res = await apiClient.get<{ data: { token: string } }>("/zoho/token");
    return res.data.data.token;
  }

  try {
    const result = await ZOHO.CRM.FUNCTIONS.execute(
      zohoAuthenticationFunctionName,
      {}
    );
    if (result.code !== "success") {
      return null;
    }
    return result.details.output as string;
  } catch (error) {
    return null;
  }
};

// min-width is always 700px and can't be changed.
const resizeWidget = async ({
  height,
  width = 700,
}: {
  height: number;
  width?: number;
}) => {
  await ZOHO.CRM.UI.Resize({ height, width });
};

const getCurrentUser = async () => {
  const data = await ZOHO.CRM.CONFIG.getCurrentUser();
  return data.users[0];
};

const getRecords = async (Entity: string, RecordID: string | string[]) => {
  const res = await ZOHO.CRM.API.getRecord({
    Entity,
    RecordID,
  });
  return res;
};

const zohoService = {
  injectScript,
  init,
  signatureForServerAPIWithUserID,
  getCurrentUser,
  resizeWidget,
  getRecords,
};

export default zohoService;
