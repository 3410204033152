import React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "theme";

export const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
