import React, { useCallback, useContext, useMemo, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {
  ActionType,
  SenderIdentitiesManagementContext,
} from "context/SenderIdentitiesManagementContext";
import {
  AddSpecializedSender,
  EditableTable,
  EditSpecializedSender,
} from "components";
import { senderIdentitiesManagementApi } from "api";
import { AxiosError } from "axios";
import notifications from "notifications";
import { EmailSenderTableData } from "SenderIdentitiesManagementWidget";
import { Column, RowActionsEnum } from "utils/types";

const columns: Column<EmailSenderTableData>[] = [
  {
    key: "slug",
    header: "Slug",
    editable: true,
    align: "left",
    info: "",
    required: true,
  },
  {
    key: "fromEmail",
    editable: true,
    header: "From email",
    align: "left",
    info: "",
    required: true,
    type: "email",
  },
  {
    key: "fromName",
    editable: true,
    header: "From name",
    align: "left",
    info: "",
    required: true,
  },
];

export const SpecializedSenders: React.FC = () => {
  const {
    state: { emailSenders, apiUrl },
    dispatch,
  } = useContext(SenderIdentitiesManagementContext);
  const [editedSender, setEditedSender] = useState<EmailSenderTableData>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const allEmailSenders = useMemo(() => {
    return (
      emailSenders
        ?.filter((sender) => !sender.IsDefault)
        .map((sender) => ({
          id: sender.Id,
          slug: sender.Slug,
          fromEmail: sender.FromEmail,
          fromName: sender.FromName,
          isDeletable: true,
        })) || []
    );
  }, [emailSenders]);

  const createSender = useCallback(
    async (data: Omit<EmailSenderTableData, "id">) => {
      try {
        await senderIdentitiesManagementApi.createSender(apiUrl, {
          Slug: data.slug,
          FromEmail: data.fromEmail,
          FromName: data.fromName,
        });
        const allSenders = await senderIdentitiesManagementApi.getSenders(
          apiUrl
        );
        dispatch({
          type: ActionType.UPDATE_EMAIL_SENDERS,
          payload: allSenders,
        });
        return true;
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
        return false;
      }
    },
    [apiUrl, dispatch]
  );

  const updateSender = useCallback(
    async (data: EmailSenderTableData) => {
      try {
        await senderIdentitiesManagementApi.updateSender(apiUrl, data.id, {
          Slug: data.slug,
          FromEmail: data.fromEmail,
          FromName: data.fromName,
        });
        const allSenders = await senderIdentitiesManagementApi.getSenders(
          apiUrl
        );
        dispatch({
          type: ActionType.UPDATE_EMAIL_SENDERS,
          payload: allSenders,
        });
        return true;
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
        return false;
      }
    },
    [apiUrl, dispatch]
  );

  const deleteSender = useCallback(
    async (id: string) => {
      try {
        await senderIdentitiesManagementApi.deleteSender(apiUrl, id);
        const allSenders = await senderIdentitiesManagementApi.getSenders(
          apiUrl
        );
        dispatch({
          type: ActionType.UPDATE_EMAIL_SENDERS,
          payload: allSenders,
        });
        return true;
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
        return false;
      }
    },
    [apiUrl, dispatch]
  );

  const toggleEditModal = useCallback(
    (id: string) => {
      const currentField = allEmailSenders.find((field) => field.id === id);
      setEditedSender(currentField);
      setIsEditModalOpen(true);
    },
    [allEmailSenders]
  );

  const toggleAddModal = useCallback(
    (open: boolean) => () => {
      setIsAddModalOpen(open);
    },
    []
  );

  const validateCell = useCallback(
    (
      row: EmailSenderTableData,
      val: string,
      dataKey: keyof EmailSenderTableData,
      required: boolean
    ) => {
      const error = false;
      const helperText = "";
      if ((val as unknown as number) !== 0 && !val && required) {
        return {
          error: true,
          helperText: `${dataKey} is required`,
        };
      }

      return {
        error,
        helperText,
      };
    },
    []
  );

  return (
    <>
      <Typography variant="h6" component="h2" gutterBottom>
        Specialized Senders
      </Typography>
      <Box mb={2}>
        <EditableTable<EmailSenderTableData>
          columns={columns}
          actions={[RowActionsEnum.Edit]}
          handlers={{
            onRowUpdate: updateSender,
            onRowCreate: createSender,
            onRowDelete: deleteSender,
          }}
          title=""
          description=""
          entity="Specialized Sender"
          rows={allEmailSenders}
          validateCell={validateCell}
          toggleEditModal={toggleEditModal}
          deleteMessage={(row: EmailSenderTableData) =>
            `Are you sure you want to delete "${row.slug}" specialized sender?`
          }
        />
      </Box>
      <Box mb={2}>
        <Button
          onClick={toggleAddModal(true)}
          startIcon={<AddIcon />}
          variant="outlined"
          color="primary"
        >
          Add a Specialized Sender
        </Button>
      </Box>
      <EditSpecializedSender
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        specializedSender={editedSender}
      />

      <AddSpecializedSender
        open={isAddModalOpen}
        handleClose={toggleAddModal(false)}
      />
    </>
  );
};
