import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import { senderIdentitiesManagementApi } from "api";
import { AxiosError } from "axios";
import notifications from "notifications";
import { Loader } from "components";
import {
  EmailSenderTableData,
  UpdateSenderRequest,
} from "SenderIdentitiesManagementWidget";
import {
  ActionType,
  SenderIdentitiesManagementContext,
} from "context/SenderIdentitiesManagementContext";

interface EditSpecializedSenderProps {
  open: boolean;
  handleClose: () => void;
  specializedSender?: EmailSenderTableData;
}

export const EditSpecializedSender: React.FC<EditSpecializedSenderProps> = ({
  open,
  handleClose,
  specializedSender,
}) => {
  const {
    state: { apiUrl },
    dispatch,
  } = useContext(SenderIdentitiesManagementContext);
  const [values, setValues] = useState<Omit<EmailSenderTableData, "id">>({
    slug: "",
    fromEmail: "",
    fromName: "",
  });
  const [updating, setUpdating] = useState(false);

  const partSlug = useMemo(
    () => specializedSender?.slug.split(".").slice(0, -1).join("."),
    [specializedSender]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setValues((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const onSave = useCallback(async () => {
    try {
      setUpdating(true);
      const payload = {
        FromEmail: values.fromEmail,
        FromName: values.fromName,
        Slug: `${partSlug}.${values.slug}`,
      } as UpdateSenderRequest;
      await senderIdentitiesManagementApi.updateSender(
        apiUrl,
        specializedSender?.id || "",
        payload
      );
      const allEmailSenders = await senderIdentitiesManagementApi.getSenders(
        apiUrl
      );
      dispatch({
        type: ActionType.UPDATE_EMAIL_SENDERS,
        payload: allEmailSenders,
      });
      handleClose();
    } catch (error) {
      const err = error as AxiosError<any>;
      notifications.notifyError(
        (err.response?.data?.message as string) || err.message
      );
    } finally {
      setUpdating(false);
    }
  }, [values, apiUrl, dispatch, handleClose, partSlug, specializedSender]);

  useEffect(() => {
    if (specializedSender) {
      setValues({
        slug: specializedSender.slug.split(".").pop() || "",
        fromEmail: specializedSender.fromEmail,
        fromName: specializedSender.fromName,
      });
    }
  }, [specializedSender]);

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <Loader open={updating} />
        <DialogTitle>Edit Specialized Sender</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 12,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box mb={4}>
            <Typography variant="body2">Slug*</Typography>
            <Box display="flex" alignItems="center" gridColumnGap="8px">
              <Typography variant="body2">{partSlug}.</Typography>
              <TextField
                required
                size="small"
                id="slug"
                name="slug"
                value={values.slug}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Box>
          </Box>
          <Box mb={2}>
            <TextField
              required
              size="small"
              id="fromEmail"
              name="fromEmail"
              value={values.fromEmail}
              onChange={handleChange}
              label="From Email"
              type="email"
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              size="small"
              id="fromName"
              name="fromName"
              value={values.fromName}
              onChange={handleChange}
              label="From Name"
              fullWidth
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!values.fromEmail || !values.fromName || !values.slug}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
