import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core";

interface TableHeaderTitle {
  title: string | React.ReactNode;
  align?: "left" | "center" | "right";
  id: string;
  maxWidth?: string;
  minWidth?: string;
}

export interface RowData {
  name: string;
  align?: "left" | "center" | "right";
  [key: string]: string | number | React.ReactNode;
}

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  defaultTableStyle: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: "4px",
    maxHeight: "calc(100vh - 225px)",
  },
  tableHead: {
    backgroundColor: p.grey[100],
  },
  tableCell: {
    padding: s(1),
  },
}));

interface BasicTableProps {
  tableClassName?: string;
  tableHeaders: TableHeaderTitle[];
  tableData: RowData[];
  emptyMessage?: string;
  pagination?: React.ReactNode;
}

export const BasicTable: React.FC<BasicTableProps> = ({
  tableClassName = "",
  tableHeaders,
  tableData,
  emptyMessage = "",
  pagination,
}) => {
  const classes = useStyle();
  return (
    <Box>
      <TableContainer className={classes.defaultTableStyle}>
        <Table
          stickyHeader
          className={tableClassName}
          aria-label="simple table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell
                  style={{
                    maxWidth: `${header.maxWidth || "auto"} !important`,
                    minWidth: header.minWidth || "auto",
                  }}
                  className={classes.tableCell}
                  key={header.id}
                  align={header.align || "left"}
                >
                  {header.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.name}>
                {tableHeaders.map(({ id }) => (
                  <TableCell
                    key={id}
                    style={{
                      maxWidth: `${
                        tableHeaders.find((header) => header.id === id)
                          ?.maxWidth || "auto"
                      } !important`,
                    }}
                    className={classes.tableCell}
                    align={row.align || "left"}
                  >
                    {row[id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {tableData.length === 0 && (
          <Box py={2} textAlign="center">
            <Typography variant="body2">
              {emptyMessage || "No data found"}
            </Typography>
          </Box>
        )}
      </TableContainer>
      <Box display="flex" justifyContent="end" alignItems="center">
        {pagination}
      </Box>
    </Box>
  );
};
