import React, {
  useReducer,
  createContext,
  Dispatch,
  FC,
  ReactNode,
} from "react";
import { EmailSender } from "SenderIdentitiesManagementWidget";

interface State {
  apiUrl: string;
  emailSenders?: EmailSender[];
}

export enum ActionType {
  SET_API_URL = "SET_API_URL",
  UPDATE_EMAIL_SENDERS = "UPDATE_EMAIL_SENDERS",
}

export interface SenderIdentitiesManagementAction {
  type: ActionType;
  payload?: any;
}

export interface ISenderIdentitiesManagementContext {
  state: State;
  dispatch: Dispatch<SenderIdentitiesManagementAction>;
}

const defaultState: State = {
  apiUrl: "",
};

const defaultDispatch: Dispatch<SenderIdentitiesManagementAction> = () => {
  throw new Error("Dispatch function must be overridden");
};

export const SenderIdentitiesManagementContext =
  createContext<ISenderIdentitiesManagementContext>({
    state: defaultState,
    dispatch: defaultDispatch,
  });

const reducer = (
  state: State,
  action: SenderIdentitiesManagementAction
): State => {
  switch (action.type) {
    case ActionType.SET_API_URL:
      return {
        ...state,
        apiUrl: action.payload ?? state.apiUrl,
      };
    case ActionType.UPDATE_EMAIL_SENDERS:
      return {
        ...state,
        emailSenders: action.payload,
      };
    default:
      return state;
  }
};

export const SenderIdentitiesManagementContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <SenderIdentitiesManagementContext.Provider value={{ state, dispatch }}>
      {children}
    </SenderIdentitiesManagementContext.Provider>
  );
};
