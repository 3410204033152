import {
  IconButtonProps,
  createStyles,
  makeStyles,
  Theme,
  fade,
  IconButton,
} from "@material-ui/core";
import { Color } from "@material-ui/lab";
import React, { forwardRef } from "react";

interface StyledIconButtonProps extends Omit<IconButtonProps, "color"> {
  color: Color | "primary" | "secondary";
}

const useStyles = makeStyles<Theme, StyledIconButtonProps>((theme) =>
  createStyles({
    root: (props) => ({
      color: theme.palette[props.color].main,
      "&:hover": {
        backgroundColor: fade(
          theme.palette[props.color].main,
          theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    }),
  })
);

export const StyledIconButton = forwardRef<
  HTMLButtonElement,
  StyledIconButtonProps
>(({ color, ...iconButtonProps }: StyledIconButtonProps, ref) => {
  const classes = useStyles({ color });
  return (
    <IconButton
      ref={ref}
      classes={{
        root: classes.root,
      }}
      {...iconButtonProps}
    />
  );
});
