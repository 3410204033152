import React, { useCallback, useContext, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  ActionType,
  SenderIdentitiesManagementContext,
} from "context/SenderIdentitiesManagementContext";
import { EditableTable } from "components";
import { senderIdentitiesManagementApi } from "api";
import { AxiosError } from "axios";
import notifications from "notifications";
import { EmailSenderTableData } from "SenderIdentitiesManagementWidget";
import { Column, RowActionsEnum } from "utils/types";

const columns: Column<EmailSenderTableData>[] = [
  {
    key: "slug",
    header: "Slug",
    editable: false,
    align: "left",
    info: "",
    required: true,
  },
  {
    key: "fromEmail",
    editable: true,
    header: "From email",
    align: "left",
    info: "",
    required: true,
    type: "email",
  },
  {
    key: "fromName",
    editable: true,
    header: "From name",
    align: "left",
    info: "",
    required: true,
  },
];

export const DefaultSender: React.FC = () => {
  const {
    state: { emailSenders, apiUrl },
    dispatch,
  } = useContext(SenderIdentitiesManagementContext);

  const defaultEmailSenders = useMemo(() => {
    return (
      emailSenders
        ?.filter((sender) => sender.IsDefault)
        .map((sender) => ({
          id: sender.Id,
          slug: sender.Slug,
          fromEmail: sender.FromEmail,
          fromName: sender.FromName,
        })) || []
    );
  }, [emailSenders]);

  const createDefaultSender = useCallback(
    async (data: Omit<EmailSenderTableData, "id">) => {
      try {
        await senderIdentitiesManagementApi.createSender(apiUrl, {
          Slug: data.slug,
          FromEmail: data.fromEmail,
          FromName: data.fromName,
          IsDefault: true,
        });
        const allEmailSenders = await senderIdentitiesManagementApi.getSenders(
          apiUrl
        );
        dispatch({
          type: ActionType.UPDATE_EMAIL_SENDERS,
          payload: allEmailSenders,
        });
        return true;
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
        return false;
      }
    },
    [apiUrl, dispatch]
  );

  const updateDispensedUnit = useCallback(
    async (data: EmailSenderTableData) => {
      try {
        await senderIdentitiesManagementApi.updateSender(apiUrl, data.id, {
          Slug: data.slug,
          FromEmail: data.fromEmail,
          FromName: data.fromName,
          IsDefault: true,
        });
        const allEmailSenders = await senderIdentitiesManagementApi.getSenders(
          apiUrl
        );
        dispatch({
          type: ActionType.UPDATE_EMAIL_SENDERS,
          payload: allEmailSenders,
        });
        return true;
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
        return false;
      }
    },
    [apiUrl, dispatch]
  );

  const validateCell = useCallback(
    (
      row: EmailSenderTableData,
      val: string,
      dataKey: keyof EmailSenderTableData,
      required: boolean
    ) => {
      const error = false;
      const helperText = "";
      if ((val as unknown as number) !== 0 && !val && required) {
        return {
          error: true,
          helperText: `${dataKey} is required`,
        };
      }

      return {
        error,
        helperText,
      };
    },
    []
  );

  return (
    <>
      <Typography variant="h6" component="h2" gutterBottom>
        Default Sender
      </Typography>
      <Box>
        <EditableTable<EmailSenderTableData>
          columns={columns}
          actions={[RowActionsEnum.Edit]}
          handlers={{
            onRowUpdate: updateDispensedUnit,
            onRowCreate: createDefaultSender,
          }}
          title=""
          description=""
          rows={defaultEmailSenders}
          validateCell={validateCell}
          // disableAddNewButton={disableAddNewButton}
        />
      </Box>
    </>
  );
};
