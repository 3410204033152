export interface ObjWithId {
  id: string;
}

export interface ObjWithAny {
  [key: string]: any;
}

export enum RowActionsEnum {
  Edit = "Edit",
  ChangeStatus = "Change Status",
}

export enum StatusEnum {
  Active = "Active",
  Inactive = "Inactive",
}

export interface Data extends ObjWithId, ObjWithAny {}

export interface Column<T extends Data> {
  key: keyof Omit<T, "id">;
  header: string;
  info: string;
  editable: boolean;
  required: boolean;
  isBoolean?: boolean;
  align?: "right" | "center" | "left";
  autoFocus?: boolean;
  stickyLeft?: boolean;
  type?: string;
  select?: boolean;
  options?: string[];
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
