import React from "react";
import { Box, BoxProps, makeStyles, Theme } from "@material-ui/core";
import { Color } from "@material-ui/lab";

interface StyledChipProps extends BoxProps {
  color: Color | "primary" | "secondary";
}

const useStyles = makeStyles<Theme, StyledChipProps>(
  ({ palette, spacing }) => ({
    chip: ({ color }) => ({
      padding: `${spacing(0.25)}px ${spacing(2)}px`,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: spacing(4),
      backgroundColor: palette[color].main,
      color: palette[color].contrastText,
    }),
  })
);

export const StyledChip: React.FC<StyledChipProps> = ({ color, children }) => {
  const classes = useStyles({ color });

  return <Box className={classes.chip}>{children}</Box>;
};
