import React, { useCallback, useEffect } from "react";
import { EmailQuarantineManagerContextProvider } from "context/EmailQuarantineManagerWidget";
import { EmailQuarantineManager } from "views";
import { setAuthorizationHeader } from "api";
import zohoService from "api/zoho";
import { AxiosError } from "axios";
import notifications from "notifications";
import { Loader, AppWrapper } from "components";
import { EmailQuarantineManagerWidgetProps } from "./EmailQuarantineManagerWidget.types";

export const EmailQuarantineManagerWidget: React.FC<EmailQuarantineManagerWidgetProps> =
  ({ apiUrl }) => {
    const [tokenLoaded, setTokenLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const setToken = useCallback(async () => {
      try {
        await zohoService.injectScript();
        await zohoService.init();
        const token = await zohoService.signatureForServerAPIWithUserID();

        if (token) setAuthorizationHeader(token);
        setTokenLoaded(true);
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data.message as string) || err.message
        );
      } finally {
        setLoading(false);
      }
    }, []);

    useEffect(() => {
      setToken();
    }, [setToken]);
    return (
      <AppWrapper>
        <EmailQuarantineManagerContextProvider>
          <Loader open={loading} />
          {tokenLoaded && <EmailQuarantineManager apiUrl={apiUrl} />}
        </EmailQuarantineManagerContextProvider>
      </AppWrapper>
    );
  };
