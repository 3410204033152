import {
  GetAllEmailSendersResponse,
  UpdateSenderRequest,
} from "SenderIdentitiesManagementWidget";
import apiClient from "./client";

export const getSenders = async (baseUrl: string) => {
  const res = await apiClient.post<GetAllEmailSendersResponse>(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: "/api/v1/db/data/v1/CustomBackend/EmailSender",
      app: "NOCODB",
      method: "GET",
    }
  );
  return res.data.data?.list;
};

export const createSender = async (
  baseUrl: string,
  payload: UpdateSenderRequest
) => {
  const res = await apiClient.post(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: "/api/v1/db/data/v1/CustomBackend/EmailSender",
      app: "NOCODB",
      method: "POST",
      data: payload,
    }
  );
  return res.data;
};

export const updateSender = async (
  baseUrl: string,
  id: string,
  payload: UpdateSenderRequest
) => {
  const res = await apiClient.post(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: `/api/v1/db/data/v1/CustomBackend/EmailSender/${id}`,
      app: "NOCODB",
      method: "PATCH",
      data: payload,
    }
  );
  return res.data;
};

export const deleteSender = async (baseUrl: string, id: string) => {
  const res = await apiClient.post(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: `/api/v1/db/data/v1/CustomBackend/EmailSender/${id}`,
      app: "NOCODB",
      method: "DELETE",
    }
  );
  return res.data;
};
