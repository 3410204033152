export interface EmailSenderManagementWidgetProps {
  apiUrl: string;
}

export interface SenderControlSettings {
  StrictStagingLockout: boolean;
  CreatedAt: string;
  LastUpdateByZohoUserId: string;
  StagingEmailTrap: string;
  StagingTrapPhoneNumber: string;
  NewRolloutQuarantine: boolean;
  LastUpdateByAppUserId: string;
  Id: string; // $uuid
  RouteSMStoStagingNumber: boolean;
  LastUpdateSource: string;
  UpdatedAt: string;
  QuarantineLevel: string;
}

export interface GetSenderControlSettingsResponse {
  data: {
    list: SenderControlSettings[];
  };
}

interface FunctionalityBatch {
  Id: string; // $uuid
  Name: string;
  LastUpdateSource: string;
  CreatedAt: string;
  LastUpdateByZohoUserId: string;
  LastUpdateByAppUserId: string;
  UpdatedAt: string;
}

export interface NameIdPair {
  Id: string; // $uuid
  Name: string;
}

export interface GetFunctionalityBatchesResponse {
  data: {
    list: NameIdPair[];
  };
}

interface QuarantineRuleFunctionalityBatchesFunctionalityBatch {
  QuarantineRuleId: string; // $uuid
  FunctionalityBatchId: string; // $uuid
}

export interface QuarantineRule {
  QuarantineRecordsOnly: boolean;
  Name: string;
  LastUpdateByAppUserId: string;
  LastUpdateByZohoUserId: string;
  UpdatedAt: string;
  CreatedAt: string;
  Id: string; // $uuid
  LastUpdateSource: string;
  QuarantineMode: string;
  "FunctionalityBatch List": FunctionalityBatch[];
  QuarantineRuleFunctionalityBatchesFunctionalityBatchList: QuarantineRuleFunctionalityBatchesFunctionalityBatch[];
}

export interface CreateQuarantineRuleRequest {
  name: string;
  functionalityBatches: string[];
  quarantineMode: string;
  quarantineRecordsOnly: boolean;
}

export interface GetAllQuarantineRulesResponse {
  data: {
    list: QuarantineRule[];
  };
}

export interface UpdateSettingsRequest {
  StrictStagingLockout: boolean;
  StagingEmailTrap: string;
  RouteSMStoStagingNumber: boolean;
  StagingTrapPhoneNumber: string;
}

export interface CreateSettingsResponse {
  data: {
    Id: string; // $uuid
  };
}

export interface ObjWithId {
  id: string;
}

export interface ObjWithAny {
  [key: string]: any;
}

export enum RowActionsEnum {
  Edit = "Edit",
  ChangeStatus = "Change Status",
}

export enum StatusEnum {
  Active = "Active",
  Inactive = "Inactive",
}

export interface Data extends ObjWithId, ObjWithAny {}

export interface Column<T extends Data> {
  key: keyof Omit<T, "id">;
  header: string;
  info: string;
  editable: boolean;
  required: boolean;
  isBoolean?: boolean;
  align?: "right" | "center" | "left";
  autoFocus?: boolean;
  stickyLeft?: boolean;
  type?: string;
  select?: boolean;
  options?: string[];
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
