import React, { useCallback, useContext, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import { senderIdentitiesManagementApi } from "api";
import { AxiosError } from "axios";
import notifications from "notifications";
import { Loader } from "components";
import {
  EmailSenderTableData,
  UpdateSenderRequest,
} from "SenderIdentitiesManagementWidget";
import {
  ActionType,
  SenderIdentitiesManagementContext,
} from "context/SenderIdentitiesManagementContext";

interface AddSpecializedSenderProps {
  open: boolean;
  handleClose: () => void;
}

const slugPrefix = "esa.sender.";

export const AddSpecializedSender: React.FC<AddSpecializedSenderProps> = ({
  open,
  handleClose,
}) => {
  const {
    state: { apiUrl },
    dispatch,
  } = useContext(SenderIdentitiesManagementContext);
  const [values, setValues] = useState<Omit<EmailSenderTableData, "id">>({
    slug: "",
    fromEmail: "",
    fromName: "",
  });
  const [updating, setUpdating] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setValues((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const onSavePromise = useCallback(async () => {
    try {
      setUpdating(true);
      const formattedSlug = values.slug.startsWith(slugPrefix)
        ? values.slug
        : `${slugPrefix}${values.slug}`;
      const payload = {
        FromEmail: values.fromEmail,
        FromName: values.fromName,
        Slug: formattedSlug,
      } as UpdateSenderRequest;
      await senderIdentitiesManagementApi.createSender(apiUrl, payload);
      const allEmailSenders = await senderIdentitiesManagementApi.getSenders(
        apiUrl
      );
      dispatch({
        type: ActionType.UPDATE_EMAIL_SENDERS,
        payload: allEmailSenders,
      });
      handleClose();
    } catch (error) {
      const err = error as AxiosError<any>;
      notifications.notifyError(
        (err.response?.data?.message as string) || err.message
      );
    } finally {
      setUpdating(false);
    }
  }, [values, apiUrl, dispatch, handleClose]);

  const onSave = useCallback(() => {
    onSavePromise();
  }, [onSavePromise]);

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <Loader open={updating} />
        <DialogTitle>Edit Specialized Sender</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 12,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box mb={2}>
            <TextField
              required
              size="small"
              id="slug"
              name="slug"
              value={values.slug}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label="Slug"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              size="small"
              id="fromEmail"
              name="fromEmail"
              value={values.fromEmail}
              onChange={handleChange}
              label="From Email"
              type="email"
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              size="small"
              id="fromName"
              name="fromName"
              value={values.fromName}
              onChange={handleChange}
              label="From Name"
              fullWidth
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!values.fromEmail || !values.fromName || !values.slug}
            onClick={onSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
