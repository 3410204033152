import { emailQuarantineManagerApi } from "api";
import { AxiosError } from "axios";
import { BasicTable } from "components/BasicTable";
import { Loader } from "components/Loader";
import { EmailQuarantineManagerContext } from "context/EmailQuarantineManagerWidget";
import { EmailLog } from "EmailQuarantineManagerWidget";
import notifications from "notifications";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import { getEmailLogInFormat } from "views";
import { ViewEmailModal } from "components/ViewEmailModal";
import { TablePaginationActions } from "components";

const tableHeaders = [
  { title: "Tag", id: "tag", minWidth: "120px" },
  {
    title: "Quarantining Date/Time",
    id: "createdTime",
    minWidth: "170px",
  },
  { title: "Template Name", id: "templateName", minWidth: "170px" },
  {
    title: "Subject",
    id: "subject",
    minWidth: "220px",
  },
  {
    title: "From",
    id: "from",
    minWidth: "200px",
  },
  {
    title: "Recipient",
    id: "recipient",
    minWidth: "170px",
  },
  { title: "", id: "actions" },
];

export const DiscardedEmails: React.FC = () => {
  const {
    state: { apiUrl },
  } = useContext(EmailQuarantineManagerContext);
  const [loading, setLoading] = useState(false);
  const [selectedEmailLog, setSelectedEmailLog] = useState<EmailLog>();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  // const [sortParam, setSortParam] = useState("&order=DESC");
  const sortParam = "&order=ASC";
  const [sizePerPage, setSizePerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [emailLogs, setEmailLogs] = useState<EmailLog[]>([]);
  const [total, setTotal] = useState(0);

  const url = useMemo(() => {
    return `${apiUrl}/package/email-logs/email-logs?orderBy=createdAt&isQuarantined=true&sendingState=discarded&page=${
      currentPage + 1
    }&limit=${sizePerPage}${sortParam}`;
  }, [apiUrl, currentPage, sizePerPage, sortParam]);

  const onViewEmail = useCallback(
    (emailLog: EmailLog) => () => {
      setSelectedEmailLog(emailLog);
      setIsViewModalOpen(true);
    },
    []
  );

  const getData = useCallback(async () => {
    if (apiUrl) {
      try {
        setLoading(true);
        const response = await emailQuarantineManagerApi.getEmailLogs(url);
        setEmailLogs(response.emailLogs);
        setTotal(response.total);
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
      } finally {
        setLoading(false);
      }
    }
  }, [url, apiUrl]);

  const onEmailAction = useCallback(
    (id: string, action: "send" | "discard") => async () => {
      try {
        setLoading(true);
        if (action === "send") {
          await emailQuarantineManagerApi.sendEmail(apiUrl, id);
        }
        if (action === "discard") {
          await emailQuarantineManagerApi.discardEmail(apiUrl, id);
        }
        await getData();
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
      } finally {
        setLoading(false);
      }
    },
    [getData, apiUrl]
  );

  const memoizedData = useMemo(
    () =>
      emailLogs.map((log) => ({
        ...getEmailLogInFormat(log),
        actions: (
          <Grid container spacing={1} wrap="nowrap">
            <Grid item>
              <Button
                size="small"
                style={{ textTransform: "none" }}
                variant="outlined"
                color="primary"
                onClick={onViewEmail(log)}
              >
                View
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                style={{ textTransform: "none", minWidth: 120 }}
                variant="contained"
                color="primary"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onEmailAction(log.id, "send")}
              >
                Send Email
              </Button>
            </Grid>
          </Grid>
        ),
      })),
    [emailLogs, onViewEmail, onEmailAction]
  );

  const onCloseViewModal = useCallback(() => {
    setIsViewModalOpen(false);
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = useCallback(
    (event: { target: { value: unknown } }) => {
      const newPageSize = event.target?.value as string;
      setSizePerPage(parseInt(newPageSize, 10));
      setCurrentPage(0);
    },
    []
  );

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      <Loader open={loading} />
      <BasicTable
        tableData={memoizedData}
        tableHeaders={tableHeaders}
        pagination={
          memoizedData.length > 0 ? (
            <TablePagination
              style={{ width: "100%" }}
              rowsPerPageOptions={[5, 10, 20]}
              count={total}
              rowsPerPage={sizePerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              SelectProps={{
                style: {
                  width: "100px",
                },
              }}
            />
          ) : null
        }
      />
      <ViewEmailModal
        open={isViewModalOpen}
        emailLog={selectedEmailLog}
        handleClose={onCloseViewModal}
      />
    </>
  );
};
