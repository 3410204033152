import {
  NameIdPair,
  QuarantineRule,
  SenderControlSettings,
} from "EmailSenderManagementWidget";
import React, {
  useReducer,
  createContext,
  Dispatch,
  FC,
  ReactNode,
} from "react";

interface State {
  apiUrl: string;
  senderControlSettings?: SenderControlSettings;
  allQuarantineRules?: QuarantineRule[];
  allFunctionalityBatches?: NameIdPair[];
}

export enum ActionType {
  SET_API_URL = "SET_API_URL",
  GET_DATA = "GET_DATA",
  UPDATE_SETTINGS = "UPDATE_SETTINGS",
  UPDATE_FUNCTIONALITY_BATCHES = "UPDATE_FUNCTIONALITY_BATCHES",
  UPDATE_QUARANTINE_RULES = "UPDATE_QUARANTINE_RULES",
}

export interface EmailSenderManagementAction {
  type: ActionType;
  payload?: any;
}

export interface IEmailSenderManagementContext {
  state: State;
  dispatch: Dispatch<EmailSenderManagementAction>;
}

const defaultState: State = {
  apiUrl: "",
};

const defaultDispatch: Dispatch<EmailSenderManagementAction> = () => {
  throw new Error("Dispatch function must be overridden");
};

export const EmailSenderManagementContext =
  createContext<IEmailSenderManagementContext>({
    state: defaultState,
    dispatch: defaultDispatch,
  });

const reducer = (state: State, action: EmailSenderManagementAction): State => {
  switch (action.type) {
    case ActionType.SET_API_URL:
      return {
        ...state,
        apiUrl: action.payload ?? state.apiUrl,
      };
    case ActionType.GET_DATA:
      return {
        ...state,
        senderControlSettings: action.payload.senderControlSettings,
        allQuarantineRules: action.payload.allQuarantineRules,
        allFunctionalityBatches: action.payload.allFunctionalityBatches,
      };
    case ActionType.UPDATE_SETTINGS:
      return {
        ...state,
        senderControlSettings: action.payload,
      };
    case ActionType.UPDATE_FUNCTIONALITY_BATCHES:
      return {
        ...state,
        allFunctionalityBatches: action.payload,
      };
    case ActionType.UPDATE_QUARANTINE_RULES:
      return {
        ...state,
        allQuarantineRules: action.payload,
      };
    default:
      return state;
  }
};

export const EmailSenderManagementContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <EmailSenderManagementContext.Provider value={{ state, dispatch }}>
      {children}
    </EmailSenderManagementContext.Provider>
  );
};
