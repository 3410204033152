import React, { useCallback, useContext, useMemo, useState } from "react";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import DeleteForever from "@material-ui/icons/DeleteForever";
import {
  ActionType,
  EmailSenderManagementContext,
} from "context/EmailSenderManagementContext";
import { BasicTable } from "components/BasicTable";
import {
  AddFunctionalityBatchesModal,
  AddQuarantineRuleModal,
  DialogConfirm,
  EditQuarantineRuleModal,
} from "components";
import { QuarantineRule } from "EmailSenderManagementWidget";
import { AxiosError } from "axios";
import notifications from "notifications";
import { emailSenderManagementApi } from "api";

const tableHeaders = [
  { title: "Rule Name", id: "Name" },
  {
    title: "Functionality Batches",
    id: "FunctionalityBatch List",
    maxWidth: "350px",
  },
  { title: "Quarantine Mode", id: "QuarantineMode" },
  {
    title: "Apply to Quarantine-marked records only",
    id: "QuarantineRecordsOnly",
    maxWidth: "150px",
  },
  { title: "", id: "actions" },
];

export const NewRolloutQuaratineRules: React.FC = () => {
  const {
    dispatch,
    state: { allQuarantineRules, apiUrl },
  } = useContext(EmailSenderManagementContext);
  const [isFunctionalityBatchesModalOpen, setIsFunctionalityBatchesModalOpen] =
    useState(false);
  const [isQuarantineRuleModalOpen, setIsQuarantineRuleModalOpen] =
    useState(false);
  const [isEditQuarantineRuleModalOpen, setIsEditQuarantineRuleModalOpen] =
    useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState<QuarantineRule>();
  const [ruleToEdit, setRuleToEdit] = useState<QuarantineRule>();

  const openDeleteModal = useCallback((rule: QuarantineRule) => {
    setRuleToDelete(rule);
    setDeleting(true);
  }, []);

  const openEditModal = useCallback((rule: QuarantineRule) => {
    setRuleToEdit(rule);
    setIsEditQuarantineRuleModalOpen(true);
  }, []);

  const tableData = useMemo(() => {
    return allQuarantineRules?.length
      ? allQuarantineRules?.map((rule) => {
          return {
            name: rule.Name,
            Name: rule.Name,
            "FunctionalityBatch List": rule["FunctionalityBatch List"]
              .map((i) => i.Name)
              .join(", "),
            QuarantineMode: rule.QuarantineMode,
            QuarantineRecordsOnly: (
              <Checkbox checked={rule.QuarantineRecordsOnly} readOnly />
            ),
            actions: (
              <>
                <IconButton onClick={() => openDeleteModal(rule)}>
                  <DeleteIcon color="error" />
                </IconButton>
                <IconButton onClick={() => openEditModal(rule)}>
                  <EditIcon />
                </IconButton>
              </>
            ),
          };
        })
      : [];
  }, [allQuarantineRules, openDeleteModal, openEditModal]);

  const onDelete = useCallback(async () => {
    try {
      setLoading(true);
      await emailSenderManagementApi.deleteQuarantineRule(
        apiUrl,
        ruleToDelete?.Id || ""
      );
      const quarantineRules =
        await emailSenderManagementApi.getAllQuarantineRules(apiUrl);
      dispatch({
        type: ActionType.UPDATE_QUARANTINE_RULES,
        payload: quarantineRules,
      });
      setRuleToDelete(undefined);
      setDeleting(false);
    } catch (error) {
      const err = error as AxiosError<any>;
      notifications.notifyError(
        (err.response?.data?.message as string) || err.message
      );
    } finally {
      setLoading(false);
    }
  }, [apiUrl, ruleToDelete, dispatch]);

  const handleFunctionalityBatchModalClose = useCallback(() => {
    setIsFunctionalityBatchesModalOpen(false);
  }, []);

  const handleQuarantineRuleModalClose = useCallback(() => {
    setIsQuarantineRuleModalOpen(false);
  }, []);

  const handleEditQuarantineRuleModalClose = useCallback(() => {
    setIsEditQuarantineRuleModalOpen(false);
  }, []);

  const closeDeleting = useCallback(() => {
    setDeleting(false);
  }, []);

  return (
    <Box component={Paper} p={2}>
      <Box mb={2}>
        <Typography variant="h6" component="h2">
          New Rollout Quarantine Rules
        </Typography>
      </Box>
      <Box mb={2}>
        <BasicTable tableHeaders={tableHeaders} tableData={tableData} />
      </Box>
      <Box mb={6}>
        <Button
          onClick={() => setIsQuarantineRuleModalOpen(true)}
          variant="outlined"
          startIcon={<AddIcon />}
          color="primary"
        >
          Add Quarantine Rule
        </Button>
      </Box>
      <Box textAlign="right">
        <Button
          onClick={() => setIsFunctionalityBatchesModalOpen(true)}
          variant="outlined"
          startIcon={<SettingsIcon />}
          color="primary"
        >
          Configure Functionality Batches
        </Button>
      </Box>

      <AddFunctionalityBatchesModal
        open={isFunctionalityBatchesModalOpen}
        handleClose={handleFunctionalityBatchModalClose}
      />
      <AddQuarantineRuleModal
        open={isQuarantineRuleModalOpen}
        handleClose={handleQuarantineRuleModalClose}
      />
      <EditQuarantineRuleModal
        open={isEditQuarantineRuleModalOpen}
        handleClose={handleEditQuarantineRuleModalClose}
        quarantineRule={ruleToEdit}
      />
      <DialogConfirm
        open={deleting}
        onClose={closeDeleting}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onConfirm={onDelete}
        type="error"
        title="Delete Quarantine Rule"
        message={`Are you sure you want to delete "${ruleToDelete?.Name}" quarantine rule?`}
        confirmBtnLabel="delete"
        confirmBtnIcon={<DeleteForever />}
        loading={loading}
      />
    </Box>
  );
};
