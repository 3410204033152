import { CSSProperties } from "react";

export const formatNameToDisplay = (str?: string, nameOnly?: boolean) => {
  if (str) {
    const [initial, firstName, lastName] = str.split(" ");
    if (lastName) return `${!nameOnly ? initial : firstName} ${lastName}`;
    return `${!nameOnly ? initial : ""} ${lastName || firstName}`.trim();
  }
  return str || "--";
};

export const cssObjectToString = (obj: CSSProperties) => {
  return Object.entries(obj)
    .map(([key, value]) => {
      return `${key
        .split(/(?=[A-Z])/)
        .join("-")
        .toLowerCase()}:${value}`;
    })
    .join(";");
};

export const truncateFileName = (str: string) => {
  const parts = str.split(".");
  const name = parts.slice(0, parts.length - 1).join(".");
  const ending = parts[parts.length - 1];
  if (name.length < 11) return str;
  return `${name.slice(0, 4)}...${name.slice(name.length - 5)}.${ending}`;
};

export const truncateLongName = (str: string) => {
  const parts = str.split(",");
  const name = parts.slice(0, parts.length - 1).join(",");
  const ending = parts[parts.length - 1];
  if (name.length < 11) return str;
  return `${name.slice(0, 4)}...${name.slice(name.length - 5)}.${ending}`;
};

export const alphabeticalSort = (a: string, b: string) => {
  return a.localeCompare(b);
};

export const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  if (Number.isNaN(date.getTime())) {
    return dateString;
  }

  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const timeFormatOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat(
    "en-US",
    dateFormatOptions
  ).format(date);
  const formattedTime = new Intl.DateTimeFormat(
    "en-US",
    timeFormatOptions
  ).format(date);

  return `${formattedDate} | ${formattedTime}`;
};
