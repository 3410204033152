import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Color } from "@material-ui/lab";
import React, { forwardRef } from "react";

interface StyledButtonProps extends Omit<ButtonProps, "color"> {
  color: Color;
}

const useStyles = makeStyles<Theme, StyledButtonProps>((theme) =>
  createStyles({
    root: (props) => ({
      color: theme.palette[props.color].contrastText,
      backgroundColor: theme.palette[props.color].main,
      "&:hover": {
        backgroundColor: theme.palette[props.color].dark,
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    }),
  })
);

export const StyledButton = forwardRef<HTMLButtonElement, StyledButtonProps>(
  ({ color, ...buttonProps }: StyledButtonProps, ref) => {
    const classes = useStyles({ color });
    return (
      <Button
        ref={ref}
        classes={{
          root: classes.root,
        }}
        {...buttonProps}
      />
    );
  }
);
