import React, { useCallback, useEffect } from "react";
import { EmailSenderManagement } from "views";
import { setAuthorizationHeader } from "api";
import { AxiosError } from "axios";
import notifications from "notifications";
import { Loader, AppWrapper } from "components";
import zohoService from "api/zoho";
import { EmailSenderManagementWidgetProps } from "./EmailSenderManagement.types";
import { EmailSenderManagementContextProvider } from "../context/EmailSenderManagementContext";

export const EmailSenderManagementWidget: React.FC<EmailSenderManagementWidgetProps> =
  ({ apiUrl }) => {
    const [tokenLoaded, setTokenLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const setToken = useCallback(async () => {
      try {
        await zohoService.injectScript();
        await zohoService.init();
        const token = await zohoService.signatureForServerAPIWithUserID();

        if (token) setAuthorizationHeader(token);
        setTokenLoaded(true);
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data.message as string) || err.message
        );
      } finally {
        setLoading(false);
      }
    }, []);

    useEffect(() => {
      setToken();
    }, [setToken]);

    return (
      <AppWrapper>
        <EmailSenderManagementContextProvider>
          <Loader open={loading} />
          {tokenLoaded && <EmailSenderManagement apiUrl={apiUrl} />}
        </EmailSenderManagementContextProvider>
      </AppWrapper>
    );
  };
