import React, { useCallback, useContext, useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import {
  ActionType,
  EmailSenderManagementContext,
} from "context/EmailSenderManagementContext";
import { emailSenderManagementApi } from "api";
import { UpdateSettingsRequest } from "EmailSenderManagementWidget";
import { Loader } from "components/Loader";
import { AxiosError } from "axios";
import notifications from "notifications";

export const ConfigurationForm: React.FC = () => {
  const {
    state: { senderControlSettings, apiUrl },
    dispatch,
  } = useContext(EmailSenderManagementContext);
  const [emailChecked, setEmailChecked] = useState(
    !!senderControlSettings?.StrictStagingLockout
  );
  const [phoneChecked, setPhoneChecked] = useState(
    !!senderControlSettings?.RouteSMStoStagingNumber
  );
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.name === "emailChecked") {
        setEmailChecked(event.target.checked);
      } else if (event.target.name === "phone") {
        setPhone(event.target.value);
      } else if (event.target.name === "email") {
        setEmail(event.target.value);
      } else if (event.target.name === "phoneChecked") {
        setPhoneChecked(event.target.checked);
      }
    },
    []
  );

  const handleUpdateSettings = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        RouteSMStoStagingNumber: phoneChecked,
        StagingTrapPhoneNumber: phone,
        StrictStagingLockout: emailChecked,
        StagingEmailTrap: email,
      } as UpdateSettingsRequest;

      if (senderControlSettings?.Id) {
        await emailSenderManagementApi.updateSettings(
          apiUrl,
          payload,
          senderControlSettings.Id
        );
      } else {
        const Id = await emailSenderManagementApi.createSettings(
          apiUrl,
          payload
        );
        dispatch({
          type: ActionType.UPDATE_SETTINGS,
          payload: { ...senderControlSettings, Id },
        });
      }
      notifications.notifySuccess("Settings updated successfully");
    } catch (error) {
      const err = error as AxiosError<any>;
      notifications.notifyError(
        (err.response?.data?.message as string) || err.message
      );
    } finally {
      setLoading(false);
    }
  }, [
    apiUrl,
    dispatch,
    email,
    emailChecked,
    phone,
    phoneChecked,
    senderControlSettings,
  ]);

  const onUpdate = useCallback(() => {
    handleUpdateSettings();
  }, [handleUpdateSettings]);

  useEffect(() => {
    setEmailChecked(!!senderControlSettings?.StrictStagingLockout);
    setPhoneChecked(!!senderControlSettings?.RouteSMStoStagingNumber);
    setEmail(senderControlSettings?.StagingEmailTrap || "");
    setPhone(senderControlSettings?.StagingTrapPhoneNumber || "");
  }, [senderControlSettings]);

  return (
    <Box component={Paper} p={2} mt={2}>
      <Loader open={loading} />
      <Box mb={1}>
        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={
            <Switch
              checked={emailChecked}
              name="emailChecked"
              onChange={handleChange}
              inputProps={{ "aria-label": "strict staging lockout" }}
            />
          }
          labelPlacement="start"
          label="Strict Staging Lockout"
        />
      </Box>
      <Box maxWidth={690} mb={2}>
        <Typography style={{ fontWeight: 300 }} variant="body1">
          This should only be enabled for staging accounts. When enabled, ALL
          email and SMS traffic sent by system, with no exception, will be
          routed to a staging email trap.
        </Typography>
      </Box>
      <Box maxWidth={400} mb={2}>
        <TextField
          id="staging-trap-email"
          label="Staging Trap Email"
          variant="outlined"
          name="email"
          required
          disabled={!emailChecked}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          fullWidth
          size="small"
        />
      </Box>
      <Box mb={2}>
        <Alert severity="info">
          <Typography variant="body1">
            Any emails will be sent to staging-trap
            +&#123;recipient-username&#125;=&#123;recepient-domain&#125;@elitesoftwareautomation.com
            <br />
            e.g. emails for mike423543&#64;gmail.com will be sent to
            staging-trap&#43;mike423543&#61;gmail.com&#64;elitesoftwareautomation.com
          </Typography>
        </Alert>
      </Box>
      <Box mb={2}>
        <Alert severity="info">
          <Typography variant="body1">
            Any SMS messages will be sent to
            staging-trap&#43;SMS&#123;recipientphonenumber&#125;&#64;elitesoftwareautomation.com
            <br />
            e.g. SMS for &#43;18885265399 will be sent as email to
            staging-trap&#43;SMS&#43;18885265399&#64;elitesoftwareautomation.com
          </Typography>
        </Alert>
      </Box>
      <Box mb={1}>
        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={
            <Switch
              name="phoneChecked"
              checked={phoneChecked}
              onChange={handleChange}
              inputProps={{
                "aria-label": "route all sms to a staging trap number instead",
              }}
            />
          }
          labelPlacement="start"
          label="Route all SMS to a staging trap number instead"
        />
      </Box>
      <Box mb={2} maxWidth={400}>
        <TextField
          id="staging-trap-phone"
          name="phone"
          label="Staging Trap Phone Number"
          variant="outlined"
          required
          disabled={!phoneChecked}
          value={phone}
          onChange={handleChange}
          type="tel"
          fullWidth
          size="small"
        />
      </Box>
      <Box textAlign="right">
        <Button
          onClick={onUpdate}
          disabled={(emailChecked && !email) || (phoneChecked && !phone)}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};
