import React, { useCallback, useContext, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  ActionType,
  EmailSenderManagementContext,
} from "context/EmailSenderManagementContext";
import { NameIdPair } from "EmailSenderManagementWidget";
import { emailSenderManagementApi } from "api";
import { AxiosError } from "axios";
import notifications from "notifications";
import { Loader } from "components";

interface AddFunctionalityBatchesModalProps {
  open: boolean;
  handleClose: () => void;
}

export const AddFunctionalityBatchesModal: React.FC<AddFunctionalityBatchesModalProps> =
  ({ open, handleClose }) => {
    const {
      state: { allFunctionalityBatches, apiUrl },
      dispatch,
    } = useContext(EmailSenderManagementContext);
    const [values, setValues] = useState<NameIdPair[]>([]);
    const [deleting, setDeleting] = useState(false);
    const [updating, setUpdating] = useState(false);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setValues((prev) =>
          prev.map((batch) =>
            batch.Id === id ? { ...batch, Name: value } : batch
          )
        );
      },
      []
    );

    const addNewBatch = useCallback(() => {
      setValues((prev) => [
        ...prev,
        { Id: `new-${Math.random()}-${Date.now()}`, Name: "" },
      ]);
    }, []);

    const removeBatch = useCallback(
      async (id: string) => {
        try {
          setDeleting(true);
          if (!id.includes("new")) {
            await emailSenderManagementApi.deleteFunctionalityBatch(apiUrl, id);
          }
          setValues((prev) => prev.filter((batch) => batch.Id !== id));
        } catch (error) {
          const err = error as AxiosError<any>;
          notifications.notifyError(
            (err.response?.data?.message as string) || err.message
          );
        } finally {
          setDeleting(false);
        }
      },
      [apiUrl]
    );

    const onRemoveBatch = useCallback(
      (id: string) => () => {
        removeBatch(id);
      },
      [removeBatch]
    );

    const onSavePromise = useCallback(async () => {
      try {
        setUpdating(true);
        await Promise.all(
          values.map(async (batch) => {
            if (!batch.Id.includes("new")) {
              await emailSenderManagementApi.updateFunctionalityBatch(
                apiUrl,
                batch
              );
            } else {
              await emailSenderManagementApi.createFunctionalityBatch(
                apiUrl,
                batch
              );
            }
          })
        );
        const functionalityBatches =
          await emailSenderManagementApi.getAllFunctionalityBatches(apiUrl);
        dispatch({
          type: ActionType.UPDATE_FUNCTIONALITY_BATCHES,
          payload: functionalityBatches,
        });

        handleClose();
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
      } finally {
        setUpdating(false);
      }
    }, [values, apiUrl, dispatch, handleClose]);

    const onSave = useCallback(() => {
      onSavePromise();
    }, [onSavePromise]);

    useEffect(() => {
      if (allFunctionalityBatches) {
        setValues(allFunctionalityBatches);
      }
    }, [allFunctionalityBatches]);

    return (
      <>
        <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
          <Loader open={updating || deleting} />
          <DialogTitle>Configure Functionality Batches</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 12,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            {values.map((batch) => (
              <Grid
                container
                spacing={2}
                key={batch.Id}
                style={{ marginBottom: 16 }}
              >
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    required
                    disabled={batch.Name === "Uncategorized"}
                    size="small"
                    id={batch.Id}
                    value={batch.Name}
                    onChange={handleChange}
                    label="Batch Name"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                {batch.Name !== "Uncategorized" && (
                  <Grid item>
                    <IconButton
                      disabled={deleting}
                      onClick={onRemoveBatch(batch.Id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={addNewBatch}
              variant="outlined"
              color="primary"
            >
              Add
            </Button>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={onSave} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
