import React, { useCallback, useContext, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  ActionType,
  EmailSenderManagementContext,
} from "context/EmailSenderManagementContext";
import { NameIdPair, QuarantineRule } from "EmailSenderManagementWidget";
import { emailSenderManagementApi } from "api";
import { AxiosError } from "axios";
import notifications from "notifications";
import { Loader, QuarantineModeLabel } from "components";

interface EditQuarantineRuleModalProps {
  open: boolean;
  handleClose: () => void;
  quarantineRule?: QuarantineRule;
}

interface Values {
  Name: string;
  FunctionalityBatchList: NameIdPair[];
  QuarantineMode: string;
  QuarantineRecordsOnly: boolean;
}

export const EditQuarantineRuleModal: React.FC<EditQuarantineRuleModalProps> =
  ({ open, handleClose, quarantineRule }) => {
    const {
      state: { allFunctionalityBatches, apiUrl },
      dispatch,
    } = useContext(EmailSenderManagementContext);
    const [values, setValues] = useState<Values>({
      Name: "",
      FunctionalityBatchList: [],
      QuarantineMode: "",
      QuarantineRecordsOnly: false,
    });
    const [updating, setUpdating] = useState(false);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value, name } = e.target;
        setValues((prev) => ({ ...prev, [name]: value }));
      },
      []
    );

    const handleCheckboxChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = e.target;
        setValues((prev) => ({ ...prev, [name]: checked }));
      },
      []
    );

    const handleAutocompleteChange = useCallback(
      (e: React.ChangeEvent<unknown>, value: NameIdPair[]) => {
        setValues((prev) => ({ ...prev, FunctionalityBatchList: value }));
      },
      []
    );

    const onSave = useCallback(async () => {
      try {
        setUpdating(true);
        const payload = {
          name: values.Name,
          functionalityBatches: values.FunctionalityBatchList.map((i) => i.Id),
          quarantineRecordsOnly: values.QuarantineRecordsOnly,
          quarantineMode: values.QuarantineMode,
        };
        await emailSenderManagementApi.updateQuarantineRule(
          apiUrl,
          quarantineRule!.Id,
          payload
        );
        const allQuarantineRules =
          await emailSenderManagementApi.getAllQuarantineRules(apiUrl);
        dispatch({
          type: ActionType.UPDATE_QUARANTINE_RULES,
          payload: allQuarantineRules,
        });
        handleClose();
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
      } finally {
        setUpdating(false);
      }
    }, [values, apiUrl, dispatch, handleClose, quarantineRule]);

    useEffect(() => {
      if (quarantineRule && allFunctionalityBatches) {
        setValues({
          Name: quarantineRule.Name,
          FunctionalityBatchList: allFunctionalityBatches.filter((batch) => {
            return quarantineRule["FunctionalityBatch List"].some(
              (i) => i.Id === batch.Id
            );
          }),
          QuarantineMode: quarantineRule.QuarantineMode,
          QuarantineRecordsOnly: quarantineRule.QuarantineRecordsOnly,
        });
      }
    }, [allFunctionalityBatches, quarantineRule]);

    return (
      <>
        <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
          <Loader open={updating} />
          <DialogTitle>Add Quarantine Rule</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 12,
              top: 12,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Box mb={2}>
              <TextField
                required
                size="small"
                id="Name"
                name="Name"
                value={values.Name}
                onChange={handleChange}
                label="Rule Name"
                fullWidth
                variant="outlined"
              />
            </Box>
            <Box mb={2}>
              <Autocomplete
                multiple
                size="small"
                onChange={handleAutocompleteChange}
                value={values.FunctionalityBatchList}
                id="FunctionalityBatchList"
                options={allFunctionalityBatches || []}
                getOptionLabel={(option: NameIdPair) => option.Name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Functionality Batches"
                    placeholder="Select"
                  />
                )}
              />
            </Box>
            <Box mb={2}>
              <RadioGroup
                aria-labelledby="quarantine-mode"
                value={values.QuarantineMode}
                onChange={handleChange}
                name="QuarantineMode"
              >
                <Box mb={1}>
                  <FormControlLabel
                    value="soft"
                    control={<Radio id="1" />}
                    label={
                      <QuarantineModeLabel
                        name="Soft Rollout Quarantine"
                        description="When Soft Quarantine enabled, only emails and SMS tagged as reminder emails or SMS will be quarantined."
                      />
                    }
                  />
                </Box>
                <Box mb={1}>
                  <FormControlLabel
                    value="strict"
                    control={<Radio />}
                    label={
                      <QuarantineModeLabel
                        name="Strict Rollout Quarantine"
                        description="When Strict Quarantine enabled, all emails and SMS except for emails and SMS designated as Ops Critical will be quarantined."
                      />
                    }
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value="complete"
                    control={<Radio />}
                    label={
                      <QuarantineModeLabel
                        name="Complete No Exception Quarantine 🚫"
                        description="When Complete No Exception Quarantine enabled, ALL email and SMS traffic will be quarantined, with no exception. WARNING! this will apply to everything, including the emails for password reset, etc. Only enable this for short time and monitor quarantine live and real-time, if this is enabled."
                      />
                    }
                  />
                </Box>
              </RadioGroup>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.QuarantineRecordsOnly}
                  id="QuarantineRecordsOnly"
                  name="QuarantineRecordsOnly"
                  onChange={handleCheckboxChange}
                />
              }
              label="Apply to Quarantine-marked records only"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={
                !values.Name ||
                !values.QuarantineMode ||
                !values.FunctionalityBatchList.length
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
