import React, { memo } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Backdrop } from "@material-ui/core";

const useStyles = makeStyles<Theme, { isFixed: boolean }>((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      position: (props) => (props.isFixed ? "fixed" : "absolute"),
      background: "rgba(255, 255, 255, 0.7)",
    },
  })
);

interface Props {
  open: boolean;
  isFixed?: boolean;
}

const Loader = ({ open, isFixed = false }: Props) => {
  const classes = useStyles({ isFixed });

  return (
    <Backdrop className={classes.backdrop} open={open} data-testid="loader">
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default memo(Loader);
