import React, { useCallback, useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { EmailSenderManagementWidgetProps } from "EmailSenderManagementWidget";
import {
  ConfigurationForm,
  Loader,
  NewRolloutQuaratineRules,
} from "components";
import { AxiosError } from "axios";
import { emailSenderManagementApi } from "api";
import {
  ActionType,
  EmailSenderManagementContext,
} from "context/EmailSenderManagementContext";
import notifications from "notifications";

export const EmailSenderManagement: React.FC<EmailSenderManagementWidgetProps> =
  ({ apiUrl }) => {
    const { dispatch } = useContext(EmailSenderManagementContext);
    const [loading, setLoading] = useState(false);

    const getData = useCallback(async () => {
      try {
        setLoading(true);
        dispatch({ type: ActionType.SET_API_URL, payload: apiUrl });
        const [
          senderControlSettings,
          allQuarantineRules,
          allFunctionalityBatches,
        ] = await Promise.all([
          emailSenderManagementApi.getSenderControlSettings(apiUrl),
          emailSenderManagementApi.getAllQuarantineRules(apiUrl),
          emailSenderManagementApi.getAllFunctionalityBatches(apiUrl),
        ]);
        dispatch({
          type: ActionType.GET_DATA,
          payload: {
            senderControlSettings,
            allQuarantineRules,
            allFunctionalityBatches,
          },
        });
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
      } finally {
        setLoading(false);
      }
    }, [dispatch, apiUrl]);

    useEffect(() => {
      getData();
    }, [getData]);

    return (
      <>
        <Loader open={loading} />
        <Container style={{ paddingTop: 16, paddingBottom: 16 }}>
          <Typography variant="h5" component="h1">
            Email and SMS Sending Override Management
          </Typography>
          <Box marginBottom={2}>
            <ConfigurationForm />
          </Box>
          <Box>
            <NewRolloutQuaratineRules />
          </Box>
        </Container>
      </>
    );
  };
