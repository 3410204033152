import React, { useCallback, useContext, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { SenderIdentitiesManagementWidgetProps } from "SenderIdentitiesManagementWidget";
import { Loader, SpecializedSenders, DefaultSender } from "components";
import notifications from "notifications";
import {
  ActionType,
  SenderIdentitiesManagementContext,
} from "context/SenderIdentitiesManagementContext";
import { senderIdentitiesManagementApi } from "api";
import { AxiosError } from "axios";

export const SenderIdentitiesManagement: React.FC<SenderIdentitiesManagementWidgetProps> =
  ({ apiUrl }) => {
    const { dispatch } = useContext(SenderIdentitiesManagementContext);
    const [loading, setLoading] = useState(false);

    const getData = useCallback(async () => {
      try {
        setLoading(true);
        dispatch({ type: ActionType.SET_API_URL, payload: apiUrl });
        const [emailSenders] = await Promise.all([
          senderIdentitiesManagementApi.getSenders(apiUrl),
        ]);

        dispatch({
          type: ActionType.UPDATE_EMAIL_SENDERS,
          payload: emailSenders,
        });
      } catch (error) {
        const err = error as AxiosError<any>;
        notifications.notifyError(
          (err.response?.data?.message as string) || err.message
        );
      } finally {
        setLoading(false);
      }
    }, [dispatch, apiUrl]);

    useEffect(() => {
      getData();
    }, [getData]);

    return (
      <>
        <Loader open={loading} />
        <Container style={{ paddingTop: 16, paddingBottom: 16 }}>
          <Box marginBottom={2}>
            <Typography variant="h5" component="h1">
              Sender Identities Management
            </Typography>
          </Box>
          <Box maxWidth={690} marginBottom={2}>
            <Alert severity="warning">
              Warning: You must use authenticated domains only! Please ensure
              Sendgrid has the domains you are about to use properly
              authenticated.
            </Alert>
          </Box>
          <Box marginBottom={2}>
            <DefaultSender />
          </Box>
          <Box>
            <SpecializedSenders />
          </Box>
        </Container>
      </>
    );
  };
