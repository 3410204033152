import axios from "axios";

const apiClient = axios.create({
  timeout: 10000,
  timeoutErrorMessage:
    "Error caused by poor internet connection or slow server.",
});

export const setBaseURL = (baseURL: string) => {
  apiClient.defaults.baseURL = baseURL;
};

export const setAuthorizationHeader = (token: string) => {
  apiClient.defaults.headers.Authorization = `Bearer ${token}`;
};

export default apiClient;
