import { GetEmailLogsResponse } from "EmailQuarantineManagerWidget";
import apiClient from "./client";

export const getEmailLogs = async (url: string) => {
  const res = await apiClient.get<GetEmailLogsResponse>(url);
  return res.data.data;
};

export const sendEmail = async (baseUrl: string, id: string) => {
  const res = await apiClient.post(
    `${baseUrl}/package/email-logs/email/sendEmail/quarantined/${id}`
  );
  return res.data;
};

export const discardEmail = async (baseUrl: string, id: string) => {
  const res = await apiClient.put(
    `${baseUrl}/package/email-logs/email-logs/discard/${id}`
  );
  return res.data;
};
