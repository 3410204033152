import React, { useMemo, useState, useCallback, useEffect, memo } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  TableRowProps,
  styled,
  Box,
  Tooltip,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  Cancel,
  CheckCircle,
  Delete,
  DeleteForever,
  MoreHoriz,
  Edit,
} from "@material-ui/icons";
import { Column, Data, RowActionsEnum, StatusEnum } from "utils/types";
import { StyledIconButton } from "components/StyledIconButton";
import { DialogConfirm } from "components/DialogConfirm";
import { EditableCell } from "../EditableCell";

const isEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);

const StyledBox = styled(Box)(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  width: 108,
  padding: `3px 0 !important`,
  paddingRight: `${theme.spacing(0.5)} !important`,
}));

export type TableEntity = "Specialized Sender" | "Field Mappings";

const getStatusDialogText = (entity?: TableEntity) => {
  if (!entity) return "";

  return "";
};

export interface RowHandlers<T extends Data> {
  onRowUpdate?: (data: T) => Promise<boolean>;
  onRowChangeSatus?: (data: T) => boolean;
  onRowDelete?: (id: string) => Promise<boolean>;
  onRowCreate?: (data: Omit<T, "id">) => Promise<boolean>;
  onAcknowledgeUpdate?: (row: T) => any;
}

interface EditableRowProps<T extends Data> extends TableRowProps {
  actions: RowActionsEnum[];
  deleteMessage?: string | React.ReactNode | ((row: T) => string);
  handlers: RowHandlers<T>;
  validate: (
    row: T,
    val: any,
    dataKey: Column<T>["key"],
    required: boolean
  ) => {
    error: boolean;
    helperText: string;
  };
  row: T;
  columns: Column<T>[];
  creating?: boolean;
  onCancel?: () => void;
  columnsWidths?: Partial<Record<Exclude<keyof T, "id">, number>>;
  entity?: TableEntity;
  showDetails?: (id: string) => void;
  toggleEditModal?: (id: string) => void;
}

function EditableRow<T extends Data>({
  actions,
  handlers: {
    onRowUpdate,
    onRowCreate,
    onRowChangeSatus,
    onRowDelete,
    onAcknowledgeUpdate,
  },
  columns,
  row,
  validate,
  creating = false,
  onCancel,
  columnsWidths,
  deleteMessage = "Are you sure you want to delete this item?",
  entity,
  showDetails,
  toggleEditModal,
  ...rest
}: EditableRowProps<T>) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(row);
  useEffect(() => {
    if (row && !creating) {
      setData(row);
    }
  }, [row, creating]);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const setHasError = useCallback(
    (dataKey: Column<T>["key"], hasError: boolean) => {
      setErrors((prev) => ({
        ...prev,
        [dataKey]: hasError,
      }));
    },
    []
  );
  const hasError = useMemo(
    () => !!Object.values(errors).filter((val) => !!val).length,
    [errors]
  );

  const onCellChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, dataKey: Column<T>["key"]) => {
      setData({
        ...data,
        [dataKey]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
    },
    [data]
  );

  const disableSave = useMemo(
    () => hasError || isEqual(data, row),
    [data, hasError, row]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const toggleEditing = useCallback(() => {
    setEditing((prev) => !prev);
    handleClose();
  }, [handleClose]);

  const onCancelClick = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      setData(row);
      setEditing(false);
    }
  }, [onCancel, row]);

  const onSaveClick = useCallback(
    async (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<HTMLTableRowElement>
    ) => {
      event.preventDefault();
      const successful =
        creating && onRowCreate
          ? await onRowCreate(data)
          : await onRowUpdate!(data);
      if (successful) {
        if (onCancel) {
          onCancel();
        } else {
          setEditing(false);
        }
      }
    },
    [creating, data, onCancel, onRowCreate, onRowUpdate]
  );

  const [deactivating, setDeactivating] = useState(false);
  const toggleDeactivating = useCallback(() => {
    setDeactivating((prev) => !prev);
    handleClose();
  }, [handleClose]);

  const onChangeStatus = useCallback(() => {
    if (onRowChangeSatus) {
      onRowChangeSatus(data);
    }
  }, [data, onRowChangeSatus]);

  const [deleting, setDeleting] = useState(false);
  const toggleDeleting = useCallback(() => {
    setDeleting((prev) => !prev);
    handleClose();
  }, [handleClose]);

  const onDelete = useCallback(async () => {
    if (onRowDelete) {
      setLoading(true);
      const deleted = await onRowDelete(row.id);
      if (deleted) {
        toggleDeleting();
      }
      setLoading(false);
    }
  }, [onRowDelete, row.id, toggleDeleting]);

  const onAcknowledgeUpdateClick = useCallback(() => {
    if (onAcknowledgeUpdate) {
      onAcknowledgeUpdate(data);
    }
  }, [data, onAcknowledgeUpdate]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTableRowElement>) => {
      if (e.keyCode === 13 && disableSave) {
        return;
      }
      const element = e.target as HTMLElement;
      if (
        e.keyCode === 13 &&
        element.tagName === "BUTTON" &&
        (element.getAttribute("aria-label") !== "save" ||
          element.getAttribute("aria-label") !== "okay")
      ) {
        return;
      }
      if (
        e.keyCode === 13 &&
        (e.target as HTMLInputElement).type !== "textarea"
      ) {
        onSaveClick(e);
      } else if (
        e.keyCode === 13 &&
        (e.target as HTMLInputElement).type === "textarea" &&
        e.shiftKey
      ) {
        onSaveClick(e);
      } else if (e.keyCode === 27) {
        onCancelClick();
      }
    },
    [disableSave, onCancelClick, onSaveClick]
  );

  const statusAction =
    row.status === StatusEnum.Active ? "Deactivate" : "Activate";

  const deactivateDialogText = useMemo(
    () => getStatusDialogText(entity),
    [entity]
  );

  return (
    <>
      <TableRow onKeyDown={onKeyDown} {...rest}>
        {columns.map((col) => {
          if (col.editable) {
            return (
              <EditableCell<T>
                key={col.key as string}
                row={data}
                type={col.type}
                select={col.select}
                options={col.options}
                dataKey={col.key}
                editing={editing || creating || (row.isNew && col.isBoolean)}
                data={data[col.key]}
                onCellChange={onCellChange}
                align={col.align}
                width={columnsWidths ? columnsWidths[col.key] : undefined}
                setHasError={setHasError}
                validate={validate}
                textFieldProps={{
                  placeholder: col.header,
                  autoFocus: col.autoFocus,
                }}
                shouldHighlight={row.updates ? row.updates[col.key] : row.isNew}
                isNew={row.isNew}
                required={col.required}
              />
            );
          }
          return (
            <TableCell
              style={{
                width: columnsWidths ? columnsWidths[col.key] : undefined,
              }}
              key={col.key as string}
              align={col.align}
            >
              {data[col.key]}
            </TableCell>
          );
        })}
        <StyledTableCell>
          {editing || creating ? (
            <StyledBox display="flex" alignItems="center">
              {disableSave ? (
                <StyledIconButton
                  aria-label="save"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={onSaveClick}
                  disabled={disableSave}
                  color="success"
                >
                  <CheckCircle />
                </StyledIconButton>
              ) : (
                <Tooltip title="Save">
                  <StyledIconButton
                    aria-label="save"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onSaveClick}
                    disabled={disableSave}
                    color="success"
                  >
                    <CheckCircle />
                  </StyledIconButton>
                </Tooltip>
              )}

              <Tooltip title="Cancel">
                <StyledIconButton
                  color="error"
                  aria-label="cancel"
                  onClick={onCancelClick}
                >
                  <Cancel />
                </StyledIconButton>
              </Tooltip>
            </StyledBox>
          ) : (
            <StyledBox
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {row.isUpdated || row.isNew ? (
                <>
                  {hasError ? (
                    <StyledIconButton
                      color="success"
                      aria-label={row.isNew ? "save" : "okay"}
                      onClick={onAcknowledgeUpdateClick}
                      disabled={hasError}
                    >
                      <CheckCircle />
                    </StyledIconButton>
                  ) : (
                    <Tooltip title={row.isNew ? "Save" : "Okay"}>
                      <StyledIconButton
                        color="success"
                        aria-label={row.isNew ? "save" : "okay"}
                        onClick={onAcknowledgeUpdateClick}
                      >
                        <CheckCircle />
                      </StyledIconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  {actions.length > 1 ? (
                    <>
                      <Tooltip title="Actions">
                        <IconButton
                          aria-label="actions"
                          aria-controls="actions-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreHoriz />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="actions-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {actions.map((action) => {
                          if (action === RowActionsEnum.ChangeStatus) {
                            return (
                              <MenuItem
                                key={action}
                                onClick={
                                  statusAction === "Activate"
                                    ? onChangeStatus
                                    : toggleDeactivating
                                }
                              >
                                {statusAction}
                              </MenuItem>
                            );
                          }
                          return (
                            <MenuItem key={action} onClick={toggleEditing}>
                              {action}
                            </MenuItem>
                          );
                        })}
                        {row.isDeletable && (
                          <MenuItem onClick={toggleDeleting}>Delete</MenuItem>
                        )}
                      </Menu>
                    </>
                  ) : (
                    <>
                      {!toggleEditModal && (
                        <Tooltip title="Edit">
                          <IconButton aria-label="edit" onClick={toggleEditing}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      {toggleEditModal && (
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            onClick={() => toggleEditModal(row.id)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      {row.isDeletable && (
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                            onClick={toggleDeleting}
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                </>
              )}
            </StyledBox>
          )}
        </StyledTableCell>
      </TableRow>
      {onRowDelete && (
        <DialogConfirm
          open={deleting}
          onClose={toggleDeleting}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onConfirm={onDelete}
          type="error"
          title={`Delete ${entity}`}
          message={
            typeof deleteMessage === "function"
              ? deleteMessage(row)
              : deleteMessage
          }
          confirmBtnLabel="delete"
          loading={loading}
          confirmBtnIcon={<DeleteForever />}
        />
      )}
      {onRowChangeSatus && (
        <DialogConfirm
          open={deactivating}
          onClose={toggleDeactivating}
          onConfirm={onChangeStatus}
          type="warning"
          title={`${statusAction} ${entity}`}
          message={deactivateDialogText}
          confirmBtnLabel="deactivate"
        />
      )}
    </>
  );
}

export default memo(EditableRow) as typeof EditableRow;
