import {
  CreateQuarantineRuleRequest,
  CreateSettingsResponse,
  GetAllQuarantineRulesResponse,
  GetFunctionalityBatchesResponse,
  GetSenderControlSettingsResponse,
  NameIdPair,
  UpdateSettingsRequest,
} from "EmailSenderManagementWidget";
import apiClient from "./client";

export const getSenderControlSettings = async (baseUrl: string) => {
  const res = await apiClient.post<GetSenderControlSettingsResponse>(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: "/api/v1/db/data/v1/CustomBackend/SenderControlSettings",
      app: "NOCODB",
      method: "GET",
    }
  );
  return res.data.data?.list?.[0];
};

export const getAllQuarantineRules = async (baseUrl: string) => {
  const res = await apiClient.post<GetAllQuarantineRulesResponse>(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: "/api/v1/db/data/v1/CustomBackend/QuarantineRule?fields=Id,Name,QuarantineRecordsOnly,QuarantineMode,FunctionalityBatch List&nested[FunctionalityBatch List][fields]=Id,Name&limit=1000",
      app: "NOCODB",
      method: "GET",
    }
  );
  return res.data.data?.list;
};

export const getAllFunctionalityBatches = async (baseUrl: string) => {
  const res = await apiClient.post<GetFunctionalityBatchesResponse>(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: "/api/v1/db/data/v1/CustomBackend/FunctionalityBatch?fields=Name,Id&limit=1000",
      app: "NOCODB",
      method: "GET",
    }
  );
  return (
    res.data.data?.list?.sort((a) => (a.Name === "Uncategorized" ? -1 : 1)) ||
    []
  );
};

export const deleteFunctionalityBatch = async (baseUrl: string, id: string) => {
  const res = await apiClient.post(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: `/api/v1/db/data/v1/CustomBackend/FunctionalityBatch/${id}`,
      app: "NOCODB",
      method: "DELETE",
    }
  );
  return res.data;
};

export const updateFunctionalityBatch = async (
  baseUrl: string,
  payload: NameIdPair
) => {
  const res = await apiClient.post(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: `/api/v1/db/data/v1/CustomBackend/FunctionalityBatch/${payload.Id}`,
      app: "NOCODB",
      method: "PATCH",
      data: { Name: payload.Name },
    }
  );
  return res.data;
};

export const createFunctionalityBatch = async (
  baseUrl: string,
  payload: NameIdPair
) => {
  const res = await apiClient.post(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: "/api/v1/db/data/v1/CustomBackend/FunctionalityBatch",
      app: "NOCODB",
      method: "POST",
      data: { Name: payload.Name },
    }
  );
  return res.data;
};

export const createQuarantineRule = async (
  baseUrl: string,
  payload: CreateQuarantineRuleRequest
) => {
  const res = await apiClient.post(
    `${baseUrl}/package/email-logs/quarantine-rule`,
    payload
  );
  return res.data;
};

export const updateQuarantineRule = async (
  baseUrl: string,
  id: string,
  payload: CreateQuarantineRuleRequest
) => {
  const res = await apiClient.put(
    `${baseUrl}/package/email-logs/quarantine-rule/${id}`,
    payload
  );
  return res.data;
};

export const deleteQuarantineRule = async (baseUrl: string, id: string) => {
  const res = await apiClient.delete(
    `${baseUrl}/package/email-logs/quarantine-rule/${id}`
  );
  return res.data;
};

export const createSettings = async (
  baseUrl: string,
  payload: UpdateSettingsRequest
) => {
  const res = await apiClient.post<CreateSettingsResponse>(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: `/api/v1/db/data/v1/CustomBackend/SenderControlSettings`,
      app: "NOCODB",
      method: "POST",
      data: payload,
    }
  );
  return res.data.data?.Id;
};

export const updateSettings = async (
  baseUrl: string,
  payload: UpdateSettingsRequest,
  settingsId: string
) => {
  const res = await apiClient.post(
    `${baseUrl}/package/custom-request-handling/request/crm`,
    {
      url: `/api/v1/db/data/v1/CustomBackend/SenderControlSettings/${settingsId}`,
      app: "NOCODB",
      method: "PATCH",
      data: payload,
    }
  );
  return res.data;
};
